/**
 * Userware
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: .
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { Address } from '../model/address';
// @ts-ignore
import { Business } from '../model/business';
// @ts-ignore
import { ChatToken } from '../model/chatToken';
// @ts-ignore
import { CreateUser } from '../model/createUser';
// @ts-ignore
import { Customer } from '../model/customer';
// @ts-ignore
import { LegalAgreement } from '../model/legalAgreement';
// @ts-ignore
import { MinUser } from '../model/minUser';
// @ts-ignore
import { Page } from '../model/page';
// @ts-ignore
import { Supplier } from '../model/supplier';
// @ts-ignore
import { UpdateUser } from '../model/updateUser';
// @ts-ignore
import { User } from '../model/user';
// @ts-ignore
import { UserGuide } from '../model/userGuide';
// @ts-ignore
import { UserGuideItem } from '../model/userGuideItem';
// @ts-ignore
import { UserSession } from '../model/userSession';
// @ts-ignore
import { UserwareUser } from '../model/userwareUser';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class UserService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Add an invoice address to a specific business. Requires BUSINESS_MANAGER role. Only possible if invoice address does not already exist.
     * @param businessId 
     * @param gstvSession The user session id
     * @param address 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addInvoiceAddress(businessId: string, gstvSession: string, address: Address, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<Business>;
    public addInvoiceAddress(businessId: string, gstvSession: string, address: Address, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<Business>>;
    public addInvoiceAddress(businessId: string, gstvSession: string, address: Address, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<Business>>;
    public addInvoiceAddress(businessId: string, gstvSession: string, address: Address, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (businessId === null || businessId === undefined) {
            throw new Error('Required parameter businessId was null or undefined when calling addInvoiceAddress.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling addInvoiceAddress.');
        }
        if (address === null || address === undefined) {
            throw new Error('Required parameter address was null or undefined when calling addInvoiceAddress.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/business/${this.configuration.encodeParam({name: "businessId", value: businessId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/add-invoice-address`;
        return this.httpClient.request<Business>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: address,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change email address
     * @param gstvSession The user session id
     * @param emailChangeToken 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeEmailAddress(gstvSession: string, emailChangeToken: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<User>;
    public changeEmailAddress(gstvSession: string, emailChangeToken: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<User>>;
    public changeEmailAddress(gstvSession: string, emailChangeToken: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<User>>;
    public changeEmailAddress(gstvSession: string, emailChangeToken: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling changeEmailAddress.');
        }
        if (emailChangeToken === null || emailChangeToken === undefined) {
            throw new Error('Required parameter emailChangeToken was null or undefined when calling changeEmailAddress.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (emailChangeToken !== undefined) {
            localVarFormParams = localVarFormParams.append('emailChangeToken', <any>emailChangeToken) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/user/change-email-address`;
        return this.httpClient.request<User>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change the password for the currently logged in user
     * @param gstvSession The user session id
     * @param oldPassword 
     * @param newPassword 
     * @param confirmPassword 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changePassword(gstvSession: string, oldPassword: string, newPassword: string, confirmPassword: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<User>;
    public changePassword(gstvSession: string, oldPassword: string, newPassword: string, confirmPassword: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<User>>;
    public changePassword(gstvSession: string, oldPassword: string, newPassword: string, confirmPassword: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<User>>;
    public changePassword(gstvSession: string, oldPassword: string, newPassword: string, confirmPassword: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling changePassword.');
        }
        if (oldPassword === null || oldPassword === undefined) {
            throw new Error('Required parameter oldPassword was null or undefined when calling changePassword.');
        }
        if (newPassword === null || newPassword === undefined) {
            throw new Error('Required parameter newPassword was null or undefined when calling changePassword.');
        }
        if (confirmPassword === null || confirmPassword === undefined) {
            throw new Error('Required parameter confirmPassword was null or undefined when calling changePassword.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (oldPassword !== undefined) {
            localVarFormParams = localVarFormParams.append('oldPassword', <any>oldPassword) as any || localVarFormParams;
        }
        if (newPassword !== undefined) {
            localVarFormParams = localVarFormParams.append('newPassword', <any>newPassword) as any || localVarFormParams;
        }
        if (confirmPassword !== undefined) {
            localVarFormParams = localVarFormParams.append('confirmPassword', <any>confirmPassword) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/user/change-password`;
        return this.httpClient.request<User>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Confirm legal agreement for the currently logged in user
     * @param legalAgreementId 
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public confirmLegalAgreement(legalAgreementId: string, gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<User>;
    public confirmLegalAgreement(legalAgreementId: string, gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<User>>;
    public confirmLegalAgreement(legalAgreementId: string, gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<User>>;
    public confirmLegalAgreement(legalAgreementId: string, gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (legalAgreementId === null || legalAgreementId === undefined) {
            throw new Error('Required parameter legalAgreementId was null or undefined when calling confirmLegalAgreement.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling confirmLegalAgreement.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/user/legal-agreements/confirm/${this.configuration.encodeParam({name: "legalAgreementId", value: legalAgreementId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<User>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a businesses for the current users customer. Requires CUSTOMER_OWNER role.
     * @param gstvSession The user session id
     * @param business 
     * @param uploadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBusiness(gstvSession: string, business: Business, uploadId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<Business>;
    public createBusiness(gstvSession: string, business: Business, uploadId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<Business>>;
    public createBusiness(gstvSession: string, business: Business, uploadId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<Business>>;
    public createBusiness(gstvSession: string, business: Business, uploadId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling createBusiness.');
        }
        if (business === null || business === undefined) {
            throw new Error('Required parameter business was null or undefined when calling createBusiness.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (uploadId !== undefined && uploadId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>uploadId, 'uploadId');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/business`;
        return this.httpClient.request<Business>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                body: business,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a user. Users can not have the role of CUSTOMER_OWNER. Requires BUSINESS_MANAGER role.
     * @param gstvSession The user session id
     * @param createUser 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createUser(gstvSession: string, createUser: CreateUser, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<User>;
    public createUser(gstvSession: string, createUser: CreateUser, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<User>>;
    public createUser(gstvSession: string, createUser: CreateUser, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<User>>;
    public createUser(gstvSession: string, createUser: CreateUser, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling createUser.');
        }
        if (createUser === null || createUser === undefined) {
            throw new Error('Required parameter createUser was null or undefined when calling createUser.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/user`;
        return this.httpClient.request<User>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                body: createUser,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Decline legal agreement for the currently logged in user
     * @param legalAgreementId 
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public declineLegalAgreement(legalAgreementId: string, gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<User>;
    public declineLegalAgreement(legalAgreementId: string, gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<User>>;
    public declineLegalAgreement(legalAgreementId: string, gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<User>>;
    public declineLegalAgreement(legalAgreementId: string, gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (legalAgreementId === null || legalAgreementId === undefined) {
            throw new Error('Required parameter legalAgreementId was null or undefined when calling declineLegalAgreement.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling declineLegalAgreement.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/user/legal-agreements/decline/${this.configuration.encodeParam({name: "legalAgreementId", value: legalAgreementId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<User>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a user. Requires CUSTOMER_OWNER role, will not work for customer admins. This stil needs to be implemented.
     * @param userId 
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAccount(userId: string, gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<User>;
    public deleteAccount(userId: string, gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<User>>;
    public deleteAccount(userId: string, gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<User>>;
    public deleteAccount(userId: string, gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling deleteAccount.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling deleteAccount.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/user/${this.configuration.encodeParam({name: "userId", value: userId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<User>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a businesses for the current users customer.
     * @param businessId 
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBusiness(businessId: string, gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<Business>;
    public getBusiness(businessId: string, gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<Business>>;
    public getBusiness(businessId: string, gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<Business>>;
    public getBusiness(businessId: string, gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (businessId === null || businessId === undefined) {
            throw new Error('Required parameter businessId was null or undefined when calling getBusiness.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling getBusiness.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/business/${this.configuration.encodeParam({name: "businessId", value: businessId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<Business>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the chat token for the currently logged in user
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChatToken(gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<ChatToken>;
    public getChatToken(gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<ChatToken>>;
    public getChatToken(gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<ChatToken>>;
    public getChatToken(gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling getChatToken.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/user/chat-token`;
        return this.httpClient.request<ChatToken>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the user that is logged in with the specified session id
     * @param gstvSession The user session id
     * @param adminOnly Only allow admins to login, returns a 403 for non-admin users.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCurrent(gstvSession: string, adminOnly?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<User>;
    public getCurrent(gstvSession: string, adminOnly?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<User>>;
    public getCurrent(gstvSession: string, adminOnly?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<User>>;
    public getCurrent(gstvSession: string, adminOnly?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling getCurrent.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (adminOnly !== undefined && adminOnly !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>adminOnly, 'adminOnly');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/current-user`;
        return this.httpClient.request<User>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the session for the specified session id
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCurrentSession(gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<UserSession>;
    public getCurrentSession(gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<UserSession>>;
    public getCurrentSession(gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<UserSession>>;
    public getCurrentSession(gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling getCurrentSession.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/current-session`;
        return this.httpClient.request<UserSession>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the current users customer.
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCustomer(gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<Customer>;
    public getCustomer(gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<Customer>>;
    public getCustomer(gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<Customer>>;
    public getCustomer(gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling getCustomer.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/customer/current`;
        return this.httpClient.request<Customer>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get this users user guide
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGuide(gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<UserGuide>;
    public getGuide(gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<UserGuide>>;
    public getGuide(gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<UserGuide>>;
    public getGuide(gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling getGuide.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/user-guide`;
        return this.httpClient.request<UserGuide>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get this users user guide
     * @param page 
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGuideItem(page: Page, gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<Array<UserGuideItem>>;
    public getGuideItem(page: Page, gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<Array<UserGuideItem>>>;
    public getGuideItem(page: Page, gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<Array<UserGuideItem>>>;
    public getGuideItem(page: Page, gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getGuideItem.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling getGuideItem.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/user-guide/${this.configuration.encodeParam({name: "page", value: page, in: "path", style: "simple", explode: false, dataType: "Page", dataFormat: undefined})}`;
        return this.httpClient.request<Array<UserGuideItem>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the master businesses for the current users customer.
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMasterBusiness(gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<Business>;
    public getMasterBusiness(gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<Business>>;
    public getMasterBusiness(gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<Business>>;
    public getMasterBusiness(gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling getMasterBusiness.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/business/master`;
        return this.httpClient.request<Business>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a user for the same customer (Requires BUSINESS_MANAGER role)
     * @param userId 
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUser(userId: string, gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<User>;
    public getUser(userId: string, gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<User>>;
    public getUser(userId: string, gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<User>>;
    public getUser(userId: string, gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getUser.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling getUser.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/user/${this.configuration.encodeParam({name: "userId", value: userId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<User>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of all users for this business
     * @param businessId 
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsersForBusiness(businessId: string, gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<Array<User>>;
    public getUsersForBusiness(businessId: string, gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<Array<User>>>;
    public getUsersForBusiness(businessId: string, gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<Array<User>>>;
    public getUsersForBusiness(businessId: string, gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (businessId === null || businessId === undefined) {
            throw new Error('Required parameter businessId was null or undefined when calling getUsersForBusiness.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling getUsersForBusiness.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/business/${this.configuration.encodeParam({name: "businessId", value: businessId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/users`;
        return this.httpClient.request<Array<User>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns user with specified ID unless current user is not allowed to view requested user.
     * @param userId 
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserwareUser(userId: string, gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<UserwareUser>;
    public getUserwareUser(userId: string, gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<UserwareUser>>;
    public getUserwareUser(userId: string, gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<UserwareUser>>;
    public getUserwareUser(userId: string, gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getUserwareUser.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling getUserwareUser.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/users/${this.configuration.encodeParam({name: "userId", value: userId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<UserwareUser>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List all addresses for the current users customer. Requires BUSINESS_MANAGER role.
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listAddresses(gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<Array<Address>>;
    public listAddresses(gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<Array<Address>>>;
    public listAddresses(gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<Array<Address>>>;
    public listAddresses(gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling listAddresses.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/addresses`;
        return this.httpClient.request<Array<Address>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List all businesses for the current users customer. Requires BUSINESS_MANAGER role.
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listBusinesses(gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<Array<Business>>;
    public listBusinesses(gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<Array<Business>>>;
    public listBusinesses(gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<Array<Business>>>;
    public listBusinesses(gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling listBusinesses.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/businesses`;
        return this.httpClient.request<Array<Business>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List all outstanding legal agreements for the current user.
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listOutstandingLegalAgreements(gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<Array<LegalAgreement>>;
    public listOutstandingLegalAgreements(gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<Array<LegalAgreement>>>;
    public listOutstandingLegalAgreements(gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<Array<LegalAgreement>>>;
    public listOutstandingLegalAgreements(gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling listOutstandingLegalAgreements.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/user/legal-agreements/outstanding`;
        return this.httpClient.request<Array<LegalAgreement>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all suppliers that can supply the specified business. Requires BUSINESS_MANAGER role.
     * @param businessId 
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listSuppliersForBusiness(businessId: string, gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<Array<Supplier>>;
    public listSuppliersForBusiness(businessId: string, gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<Array<Supplier>>>;
    public listSuppliersForBusiness(businessId: string, gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<Array<Supplier>>>;
    public listSuppliersForBusiness(businessId: string, gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (businessId === null || businessId === undefined) {
            throw new Error('Required parameter businessId was null or undefined when calling listSuppliersForBusiness.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling listSuppliersForBusiness.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/business/${this.configuration.encodeParam({name: "businessId", value: businessId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/suppliers`;
        return this.httpClient.request<Array<Supplier>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of all users that have the same customerId. Requires BUSINESS_MANAGER role.
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listUsers(gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<Array<User>>;
    public listUsers(gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<Array<User>>>;
    public listUsers(gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<Array<User>>>;
    public listUsers(gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling listUsers.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/users/all`;
        return this.httpClient.request<Array<User>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a supplier for the specified business. Requires BUSINESS_MANAGER role.
     * @param businessId 
     * @param supplierId 
     * @param gstvSession The user session id
     * @param customerNumber 
     * @param notes 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestAddSupplierToBusiness(businessId: string, supplierId: string, gstvSession: string, customerNumber?: string, notes?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<boolean>;
    public requestAddSupplierToBusiness(businessId: string, supplierId: string, gstvSession: string, customerNumber?: string, notes?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<boolean>>;
    public requestAddSupplierToBusiness(businessId: string, supplierId: string, gstvSession: string, customerNumber?: string, notes?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<boolean>>;
    public requestAddSupplierToBusiness(businessId: string, supplierId: string, gstvSession: string, customerNumber?: string, notes?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (businessId === null || businessId === undefined) {
            throw new Error('Required parameter businessId was null or undefined when calling requestAddSupplierToBusiness.');
        }
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling requestAddSupplierToBusiness.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling requestAddSupplierToBusiness.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (customerNumber !== undefined) {
            localVarFormParams = localVarFormParams.append('customerNumber', <any>customerNumber) as any || localVarFormParams;
        }
        if (notes !== undefined) {
            localVarFormParams = localVarFormParams.append('notes', <any>notes) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/business/${this.configuration.encodeParam({name: "businessId", value: businessId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<boolean>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request the change of a business. (Requires BUSINESS_MANAGER role)
     * @param businessId 
     * @param gstvSession The user session id
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestBusinessChange(businessId: string, gstvSession: string, body: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<boolean>;
    public requestBusinessChange(businessId: string, gstvSession: string, body: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<boolean>>;
    public requestBusinessChange(businessId: string, gstvSession: string, body: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<boolean>>;
    public requestBusinessChange(businessId: string, gstvSession: string, body: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (businessId === null || businessId === undefined) {
            throw new Error('Required parameter businessId was null or undefined when calling requestBusinessChange.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling requestBusinessChange.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling requestBusinessChange.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/business/${this.configuration.encodeParam({name: "businessId", value: businessId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/request-change`;
        return this.httpClient.request<boolean>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: body,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request the deletion of a business. (Requires CUSTOMER_OWNER role)
     * @param businessId 
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestBusinessDeletion(businessId: string, gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<Business>;
    public requestBusinessDeletion(businessId: string, gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<Business>>;
    public requestBusinessDeletion(businessId: string, gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<Business>>;
    public requestBusinessDeletion(businessId: string, gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (businessId === null || businessId === undefined) {
            throw new Error('Required parameter businessId was null or undefined when calling requestBusinessDeletion.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling requestBusinessDeletion.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/business/${this.configuration.encodeParam({name: "businessId", value: businessId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/request-delete`;
        return this.httpClient.request<Business>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *  (Request the deletion of this customer. CUSTOMER_OWNER role)
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestCustomerDeletion(gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<boolean>;
    public requestCustomerDeletion(gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<boolean>>;
    public requestCustomerDeletion(gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<boolean>>;
    public requestCustomerDeletion(gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling requestCustomerDeletion.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/customer/request-delete`;
        return this.httpClient.request<boolean>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request email address change
     * @param gstvSession The user session id
     * @param newEmailAddress 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestEmailAddressChange(gstvSession: string, newEmailAddress: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any>;
    public requestEmailAddressChange(gstvSession: string, newEmailAddress: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<any>>;
    public requestEmailAddressChange(gstvSession: string, newEmailAddress: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<any>>;
    public requestEmailAddressChange(gstvSession: string, newEmailAddress: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling requestEmailAddressChange.');
        }
        if (newEmailAddress === null || newEmailAddress === undefined) {
            throw new Error('Required parameter newEmailAddress was null or undefined when calling requestEmailAddressChange.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (newEmailAddress !== undefined) {
            localVarFormParams = localVarFormParams.append('newEmailAddress', <any>newEmailAddress) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/user/request-email-address-change`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Resend invite to user. (Requires BUSINESS_MANAGER role)
     * @param userId 
     * @param gstvSession The user session id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resendInvite(userId: string, gstvSession: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<boolean>;
    public resendInvite(userId: string, gstvSession: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<boolean>>;
    public resendInvite(userId: string, gstvSession: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<boolean>>;
    public resendInvite(userId: string, gstvSession: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling resendInvite.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling resendInvite.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/user/resend-invite/${this.configuration.encodeParam({name: "userId", value: userId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<boolean>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set business for current session. Returns the updated session if successful
     * @param businessId 
     * @param gstvSession The user session id
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setBusinessForCurrentSession(businessId: string, gstvSession: string, body?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<UserSession>;
    public setBusinessForCurrentSession(businessId: string, gstvSession: string, body?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<UserSession>>;
    public setBusinessForCurrentSession(businessId: string, gstvSession: string, body?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<UserSession>>;
    public setBusinessForCurrentSession(businessId: string, gstvSession: string, body?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (businessId === null || businessId === undefined) {
            throw new Error('Required parameter businessId was null or undefined when calling setBusinessForCurrentSession.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling setBusinessForCurrentSession.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/current-session/set-business/${this.configuration.encodeParam({name: "businessId", value: businessId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<UserSession>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: body,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set an user guide item as done
     * @param gstvSession The user session id
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setGuideItemAsDone(gstvSession: string, body: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<UserGuide>;
    public setGuideItemAsDone(gstvSession: string, body: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<UserGuide>>;
    public setGuideItemAsDone(gstvSession: string, body: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<UserGuide>>;
    public setGuideItemAsDone(gstvSession: string, body: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling setGuideItemAsDone.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling setGuideItemAsDone.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/user-guide/done`;
        return this.httpClient.request<UserGuide>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: body,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a businesses for the current users customer. Requires BUSINESS_MANAGER role.
     * @param businessId 
     * @param gstvSession The user session id
     * @param orderConfirmationAddresses 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBusinessSettings(businessId: string, gstvSession: string, orderConfirmationAddresses?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<Business>;
    public updateBusinessSettings(businessId: string, gstvSession: string, orderConfirmationAddresses?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<Business>>;
    public updateBusinessSettings(businessId: string, gstvSession: string, orderConfirmationAddresses?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<Business>>;
    public updateBusinessSettings(businessId: string, gstvSession: string, orderConfirmationAddresses?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (businessId === null || businessId === undefined) {
            throw new Error('Required parameter businessId was null or undefined when calling updateBusinessSettings.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling updateBusinessSettings.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (orderConfirmationAddresses) {
            if (localVarUseForm) {
                orderConfirmationAddresses.forEach((element) => {
                    localVarFormParams = localVarFormParams.append('orderConfirmationAddresses', <any>element) as any || localVarFormParams;
            })
            } else {
                localVarFormParams = localVarFormParams.append('orderConfirmationAddresses', [...orderConfirmationAddresses].join(COLLECTION_FORMATS['csv'])) as any || localVarFormParams;
            }
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/business/${this.configuration.encodeParam({name: "businessId", value: businessId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/budget-managed`;
        return this.httpClient.request<Business>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a businesses for the current users customer. Requires BUSINESS_MANAGER role.
     * @param businessId 
     * @param gstvSession The user session id
     * @param taxNumber 
     * @param taxId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBusinessTaxdata(businessId: string, gstvSession: string, taxNumber?: string, taxId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<Business>;
    public updateBusinessTaxdata(businessId: string, gstvSession: string, taxNumber?: string, taxId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<Business>>;
    public updateBusinessTaxdata(businessId: string, gstvSession: string, taxNumber?: string, taxId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<Business>>;
    public updateBusinessTaxdata(businessId: string, gstvSession: string, taxNumber?: string, taxId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (businessId === null || businessId === undefined) {
            throw new Error('Required parameter businessId was null or undefined when calling updateBusinessTaxdata.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling updateBusinessTaxdata.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (taxNumber !== undefined && taxNumber !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>taxNumber, 'taxNumber');
        }
        if (taxId !== undefined && taxId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>taxId, 'taxId');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/business/${this.configuration.encodeParam({name: "businessId", value: businessId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/update-taxdata`;
        return this.httpClient.request<Business>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update self
     * @param gstvSession The user session id
     * @param minUser 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMyUser(gstvSession: string, minUser: MinUser, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<User>;
    public updateMyUser(gstvSession: string, minUser: MinUser, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<User>>;
    public updateMyUser(gstvSession: string, minUser: MinUser, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<User>>;
    public updateMyUser(gstvSession: string, minUser: MinUser, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling updateMyUser.');
        }
        if (minUser === null || minUser === undefined) {
            throw new Error('Required parameter minUser was null or undefined when calling updateMyUser.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/user/my-data`;
        return this.httpClient.request<User>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: minUser,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a user for the same customer (Requires BUSINESS_MANAGER role)
     * @param userId 
     * @param gstvSession The user session id
     * @param updateUser 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUser(userId: string, gstvSession: string, updateUser: UpdateUser, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<User>;
    public updateUser(userId: string, gstvSession: string, updateUser: UpdateUser, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpResponse<User>>;
    public updateUser(userId: string, gstvSession: string, updateUser: UpdateUser, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<HttpEvent<User>>;
    public updateUser(userId: string, gstvSession: string, updateUser: UpdateUser, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset&#x3D;utf-8',}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling updateUser.');
        }
        if (gstvSession === null || gstvSession === undefined) {
            throw new Error('Required parameter gstvSession was null or undefined when calling updateUser.');
        }
        if (updateUser === null || updateUser === undefined) {
            throw new Error('Required parameter updateUser was null or undefined when calling updateUser.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (gstvSession !== undefined && gstvSession !== null) {
            localVarHeaders = localVarHeaders.set('gstv-session', String(gstvSession));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/management/user/${this.configuration.encodeParam({name: "userId", value: userId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<User>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: updateUser,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
