import { Component, AfterContentInit, ApplicationRef } from '@angular/core';
import { Angulartics2GoogleTagManager } from "angulartics2";
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RouteService } from './services/route.service';
import { Config } from './app.config';
import { LocationService } from './location/location.service';

/**
 * This component encapsulates the whole dynamic app content
 */
@Component({
    selector: 'app',
    templateUrl: 'app.html'
})
export class AppComponent implements AfterContentInit {
    public useFailtrak = Config.userFailtrak;

    /**
     * The component's constructor
     * 
     * @param applicationRef The application reference
     * @param router The router
     * @param angulartics2GoogleTagManager The angular analytics tag manager
     * @param routeService The route service
     * @param translate The translate service
     */
    constructor(private applicationRef: ApplicationRef, private router: Router, private angulartics2GoogleTagManager: Angulartics2GoogleTagManager, private routeService: RouteService, private translateService: TranslateService, private locationService: LocationService) {
        this.translateService.setDefaultLang(this.locationService.defaultLocale());
        this.translateService.use(locationService.userLocale());

         // We don't want to track on dev...
         if (!Config.dev) {
            this.loadGTM();
        }
        this.angulartics2GoogleTagManager.startTracking();
    }

    /**
     * Remove loading animation
     */
    ngAfterContentInit() {
        $('body').removeClass('initializing');
    }

    private loadGTM() {
        const domain: string = this.locationService.getDomain();
        const gtmConfig = Config.gtmId;

        if (!gtmConfig) return;

        const gtmId = gtmConfig[domain];

        if (!gtmId) return;
        const head = document.head;
        const script = document.createElement('script');
        script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://gasdat.gastivo.de/media.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmId}');`;
        script.async = true;
        script.defer = true;
        head.appendChild(script);
    }

}
