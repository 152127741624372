import { Injectable } from '@angular/core';
import { ApplicationLanguage, LanguagesForEnvironment } from './applicationLanguage';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from '../services/cookie.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Environment, EnvironmentMap } from './environment';

declare var window: any;

interface EventListener {
    callback: Function;
    once: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class LocationService {
    private LANGUAGE_COOKIE: string = 'gstv-locale';

    private currentUserLocaleSubject: BehaviorSubject<string>;

    private origin: string;

    private eventFunctions: { [key: string]: EventListener[] } = {};
    private normalizedBrowserLanguage: string;
    /**
     * Used for tracking to know which was the last selected language. The value
     * from the cookie is sometimes stale as it only updates on network requests.
     * Perhaps the whole approach should be changed to a single source of truth
     * in the future. The value is an ISO code, eg. `de_AT`.
     */
    private currentLanguage?: string;

    constructor(
        private translateService: TranslateService,
        private cookieService: CookieService,
    ) {
        this.origin = new URL(window.location.href).origin;
        this.currentUserLocaleSubject = new BehaviorSubject<string>(this.userLocale());
        this.normalizedBrowserLanguage = this.findNormalizedBrowserLanguage();
    }

    /**
     * Gets the current tld or points to uat if local
     */
    public getTld() {
        if (this.origin.indexOf('.de') > 0) {
            return 'de';
        }
        if (this.origin.indexOf('.biz') > 0 || window.location.host === 'localhost:3000') {
            return 'biz';
        }
        if (this.origin.indexOf('.ch') > 0) {
            return 'ch';
        }
        if (this.origin.indexOf('.tv') > 0 || window.location.host === 'localhost:3001') {
            return 'tv';
        }
        if (this.origin.indexOf('.info') > 0) {
            return 'info';
        }
    }

    public getDomain(): string {
        return this.domain();
    }

    public isDomainDE(): boolean {
        return this.domain() === 'DE';
    }

    public isDomainCH(): boolean {
        return this.domain() === 'CH';
    }

    public getEnvironment(): Environment {
        return this.environment();
    }

    public defaultLocale(): string {
        return this.getAvailableApplicationLanguages()[0].isoCode;
    }

    /**
     * Returns the user's locale in a format compatible with TranslateService.
     */
    public userLocale(): string {
        const cookieLocale = this.cookieService.getCookie(this.LANGUAGE_COOKIE);
        if (this.isSupportedLocale(cookieLocale)) {
            return cookieLocale;
        }

        if (this.normalizedBrowserLanguage && this.isSupportedLocale(this.normalizedBrowserLanguage)) {
            return this.normalizedBrowserLanguage;
        }

        return this.defaultLocale();
    }

    public currentUserLocale(): Observable<string> {
        return this.currentUserLocaleSubject;
    }

    public getAvailableApplicationLanguages(): Array<ApplicationLanguage> {
        return LanguagesForEnvironment.get(this.getEnvironment());
    }

    public getCurrentLanguage(): ApplicationLanguage {
        return this.getAvailableApplicationLanguages().find((language) => language.isoCode === this.userLocale());
    }

    /**
     * Sets the current locale of the app.
     * @param locale ISO code lanuage, eg. `de_AT`
     */
    public setLanguage(locale: string) {
        this.currentLanguage = locale;
        this.cookieService.setCookie(this.LANGUAGE_COOKIE, locale, 100);
        // call TranslateService at last as this will trigger further changes
        this.translateService.use(locale);
        this.triggerEvent('language-switch');
    }

    /**
     * Sets the current locale of the app and tracks the change if Google Tag
     * Manager is available.
     * @param locale ISO code lanuage, eg. `de_AT`
     */
    public switchLanguage(locale: string) {
        const oldLanguage = this.currentLanguage || this.userLocale();
        this.setLanguage(locale);
        this.trackLanguageSwitch(oldLanguage, locale);
    }

    public onLanguageSwitch(callback: (language: string) => void) {
        this.addEventListener('language-switch', () => {
            callback(this.userLocale());
        });
    }

    private isSupportedLocale(browserLocale: string) {
        return this.getAvailableApplicationLanguages().some((locale) => locale.isoCode === browserLocale);
    }

    /**
     * Returns the current domain as 2 uppercase chars.
     */
    private domain(): string {
        let result = 'DE';
        if (
            (this.origin != null && (this.origin.indexOf('.ch') >= 0 || this.origin.indexOf('.tv') >= 0)) ||
            this.origin.indexOf('easy-ch') >= 0 ||
            window.location.host === 'localhost:3001'
        ) {
            result = 'CH';
        }
        return result;
    }

    private environment(): Environment {
        const origin = this.origin || '';
        let environment = Environment.DE_PROD;
        EnvironmentMap.forEach((environmentFromMap, regex) => {
            if (regex.test(origin)) {
                environment = environmentFromMap;
            }
        });
        return environment;
    }

    private findNormalizedBrowserLanguage(): string | null {
        const browserLanguage = navigator.language;
        if (typeof browserLanguage !== 'string' || browserLanguage.length < 2) {
            return null;
        }

        const language = browserLanguage.substring(0, 2);
        return this.getAvailableApplicationLanguages().find((loc) => loc.defaultForLanguage === language)?.isoCode;
    }

    /**
     * Tracks language change with Google Tag Manager (if available).
     * @param oldLanguage ISO code lanuage, eg. `de_AT`
     * @param newLanguage ISO code lanuage, eg. `en_GB`
     */
    private trackLanguageSwitch(oldLanguage: string, newLanguage: string): void {
        oldLanguage = this.formatLanguageForTracking(oldLanguage);
        newLanguage = this.formatLanguageForTracking(newLanguage);
        console.info('language change', oldLanguage, '›', newLanguage);
        if (!window.dataLayer) {
            return;
        }

        window.dataLayer.push({
            event: 'portal.change_language',
            event_name: 'change_language',
            old_language: oldLanguage,
            new_language: newLanguage,
        });
    }

    /**
     * Transforms an ISO code into the tracking syntax, eg. `de_AT` into `DE`.
     * @param isoCode ISO code language, eg. `de_AT`
     * @returns Language-only as uppercase, eg. `DE`
     */
    private formatLanguageForTracking(isoCode: string) {
        return isoCode.split('_')[0].toString().toUpperCase();
    }

    private addEventListener(event: string, callback: Function, once?: boolean) {
        if (this.eventFunctions[event]) {
            this.eventFunctions[event].push({
                callback: callback,
                once: once,
            });
        } else {
            this.eventFunctions[event] = [
                {
                    callback: callback,
                    once: once,
                },
            ];
        }
    }

    private triggerEvent(event: string) {
        let callbacks = this.eventFunctions[event];
        if (callbacks) {
            let del: number[] = [];
            callbacks.forEach((f, i) => {
                f.callback();
                if (f.once) {
                    del.unshift(i);
                }
            });
            del.forEach((i) => {
                this.eventFunctions[event].splice(i, 1);
            });
        }
    }
}
