import { AbstractControl, Validators } from '@angular/forms';
import * as Moment from "moment";

export class MoreValidators {
  private static emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
    private static dateRegex = /^([0-9]{1,2})(\.[0-9]{1,2})(\.[0-9]{4})$/;
    private static germanTaxVatRegNoRegex = /^DE[0-9]{9}$/;
    private static germanTaxNumberRegex = /^([0-9\/]{10,13})$/;
    private static phoneNumberRegex = /[+]?[\s0-9]{5,18}/;
    private static zipRegex = /^([0-9]{4,5})$/;
    private static zipRegexAT = /^([0-9]{4})$/;
    private static zipRegexCH = /^([0-9]{4})$/;
    private static zipRegexDE = /^([0-9]{5})$/;
    private static zipRegexLI = /^([0-9]{4})$/;
    
    public static email() {
        return function (c: AbstractControl) {
            if (!c.value || c.value.match(MoreValidators.emailRegex)) {
                return null;
            }
            return { email: true };
        }
    }

    public static phoneNumber() {
        return function (c: AbstractControl) {
            if (!c.value || c.value.match(MoreValidators.phoneNumberRegex)) {
                return null;
            }
            return { phoneNumber: true };
        }
    }

    public static zip(countryControl?: AbstractControl) {
      return function (zipControl: AbstractControl) {
          let zipRegex = MoreValidators.zipRegex
          if (countryControl) {
              countryControl.valueChanges.subscribe(() => {
                  zipControl.updateValueAndValidity()
              })
              switch (countryControl.value) {
                  case 'AT':
                      zipRegex = MoreValidators.zipRegexAT
                      break;
                  case 'CH':
                      zipRegex = MoreValidators.zipRegexCH
                      break;
                  case 'DE':
                      zipRegex = MoreValidators.zipRegexDE
                      break;
                  case 'LI':
                      zipRegex = MoreValidators.zipRegexLI
                      break;
              }
          }
          if (!zipControl.value || zipControl.value.match(zipRegex)) {
              return null;
          }
          return { zip: true };
      };
  }

    public static date() {
        return function (c: AbstractControl) {
            if (c.value) {
                if (c.value.match(MoreValidators.dateRegex)) {
                    let m = Moment(c.value, "DD.MM.YYYY");
                    if (m.isValid()) {
                        return null;
                    }
                }
                return { date: true };
            }
            return null;
        }
    }

    public static beforeDate(c1: AbstractControl) {
        var r = true;
        return function (c2: AbstractControl) {
            if (r) {
                r = false;
                c1.valueChanges.subscribe(() => c2.updateValueAndValidity());
            }
            let m1 = Moment(c1.value);
            let m2 = Moment(c2.value);
            if (m1.isValid() && m2.isValid() && m1.isBefore(m2)) {
                return null;
            }
            return { beforeDate: true };
        }
    }

    public static compareTo(c1: AbstractControl) {
        var r = true;
        return function (c2: AbstractControl) {
            if (r) {
                r = false;
                c1.valueChanges.subscribe(() => c2.updateValueAndValidity());
            }
            if (c2.value === c1.value) {
                return null;
            }
            return { compareTo: true };
        }
    }

    public static either(c1: AbstractControl) {
        var r = true;
        return function (c2: AbstractControl) {
            if (r) {
                r = false;
                c1.valueChanges.subscribe(() => {
                    c2.updateValueAndValidity();
                });
            }
            if ((c2.value && c2.value.length) || (c1.value && c1.value.length)) {
                return null;
            }
            return { either: true };
        }
    }

    public static min(limit: number) {
        return function (c: AbstractControl) {
            if (!c.value || parseFloat(c.value.toString().replace(",", ".")) >= limit) {
                return null;
            }
            return { min: true };
        }
    }

    public static max(limit: number) {
        return function (c: AbstractControl) {
            if (!c.value || parseFloat(c.value.toString().replace(",", ".")) <= limit) {
                return null;
            }
            return { max: true };
        }
    }

    public static requiredIf(ifFunction: () => boolean) {
        return function (c: AbstractControl) {
            if (ifFunction()) {
                return Validators.required(c);
            }
        }
    }

    public static germanVatRegNo() {
        return function (c: AbstractControl) {
            if (!c.value || c.value.match(MoreValidators.germanTaxVatRegNoRegex)) {
                return null
            }
            return { germanVatRegNo: true }
        }
    }

    public static germanTaxNumber() {
        return function (c: AbstractControl) {
            if (!c.value || c.value.match(MoreValidators.germanTaxNumberRegex)) {
                return null
            }
            return { germanTaxNumber: true }
        }
    }


}