<section class="easy-registration"
    [ngClass]="{'done':registrationSuccess || registrationDeclined, 'declined':declineChecked}">
    <div class="container-fluid" *ngIf="ready">
        <div class="content" [ngClass]="{'loading white': registrationForm?.loading}">
            <ng-template [ngIf]="!registrationSuccess && !registrationDeclined && showData">
                
                <div class="cont-small">
                    <img [src]="supplierLogo" [alt]="supplierName" class="supplier-logo center">
                    <h1 [innerHtml]="'REGISTRATION_TITLE'|translate:{name:supplierName}" class="center"></h1>
                    <p class="center" [innerHtml]="'REGISTRATION_TEXT'|translate"></p>
                    <p class="center" [innerHtml]="'REGISTRATION_TEXT_2'|translate"></p>
                    <p class="center" [innerHtml]="'REGISTRATION_TEXT_3'|translate"></p>
                </div>

                <div class="info-box" *ngIf="!showChange && showData">
                    <p translate>REGISTRATION_CHECK_DATA_HEADER</p>
                    <p class="customer-name">{{'SALUTATION_' + showData.salutation | translate}}
                        {{showData.firstName}} {{showData.lastName}}
                        <br>{{showData.name}}</p>
                    <p>{{showData.street}}
                        <br>{{showData.zip}} {{showData.city}}
                        <br>{{'COUNTRY_' + showData.country | translate}}
                    </p>
                    <p>
                        <span class="detail-label" translate>EMAIL</span> {{showData.email}}
                        <br>
                        <ng-template [ngIf]="showData.telefone">
                            <span class="detail-label" translate>FORM_LABEL_TELEFONE</span>
                            {{showData.telefone}}
                            <br>
                        </ng-template>
                        <ng-template [ngIf]="showData.mobile">
                            <span class="detail-label" translate>FORM_LABEL_MOBILE</span> {{showData.mobile}}
                            <br>
                        </ng-template>
                        <ng-template [ngIf]="showData.fax">
                            <span class="detail-label" translate>FORM_LABEL_FAX</span> {{showData.fax}}
                            <br>
                        </ng-template>
                        <ng-template [ngIf]="showData.taxNumber && isDE">
                            <span class="detail-label" translate>TAX_NUMBER</span> {{showData.taxNumber}}
                            <br>
                        </ng-template>
                        <ng-template [ngIf]="showData.taxId && isDE">
                            <span class="detail-label" translate>FORM_LABEL_TAX_ID</span> {{showData.taxId}}
                            <br>
                        </ng-template>
                        <span class="detail-label" translate>CUSTOMER_NUMBER</span> {{showData.customerNumber}}
                        <br>
                    </p>
                    <p class="info-buttons">
                        <a class="btn btn-primary btn-inverted" (click)="isOk = true" [ngClass]="{'btn-ok':isOk}"><i
                                class="fa fa-check"></i> {{'REGISTRATION_DATA_OK_BUTTON' | translate}}</a>
                        <a (click)="showChange = !showChange" *ngIf="!declineChecked" class="btn btn-default" [ngClass]="{'out':isOk}"
                            translate>REGISTRATION_DATA_CHANGE_BUTTON</a>
                    </p>
                    <p class="small" [innerHtml]="'REGISTRATION_DATA_SUB_TEXT'|translate"></p>
                </div>
                <form class="form-regular form-horizontal"
                    *ngIf="registrationForm && !registrationSuccess && showChange" [formGroup]="registrationForm"
                    [ngClass]="{loading:registrationForm?.loading}" (submit)="submitForm()">
                    <div class="alert alert-danger" *ngIf="registrationForm.sent && !registrationForm.valid" [innerHtml]="'FORM_ERROR_HINT'|translate"></div>
                    <h3 class="label-bg" translate>REGISTRATION_HEADER_PERSONAL_DATA</h3>
                    <div class="row">
                        <div class="col-sm-3">
                            <select-control name="salutation" [label]="'FORM_LABEL_SALUTATION'|translate"
                                [form]="registrationForm" [options]="salutationOptions"></select-control>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <input-control name="firstName" [label]="'FORM_LABEL_FIRSTNAME'|translate"
                                [form]="registrationForm" type="text"></input-control>
                        </div>
                        <div class="col-sm-6">
                            <input-control name="lastName" [label]="'FORM_LABEL_LASTNAME'|translate"
                                [form]="registrationForm" type="text"></input-control>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <input-control name="email" [label]="'FORM_LABEL_EMAIL_ADDRESS'|translate"
                                [form]="registrationForm" type="email"
                                [messages]="{'duplicate': 'REGISTRATION_ERROR_DUPLICATE_EMAIL'}"></input-control>
                        </div>
                    </div>
                    <h3 class="label-bg" translate>REGISTRATION_HEADER_BUSINESS_DATA</h3>
                    <div class="row">
                        <div class="col-sm-12">
                            <input-control name="name" [label]="'FORM_LABEL_BUSINESS_NAME'|translate"
                                [form]="registrationForm" type="text"></input-control>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <input-control name="customerNumber" [label]="'FORM_LABEL_CUSTOMER_NUMBER'|translate"
                                [form]="registrationForm" type="text"></input-control>
                        </div>
                    </div>
                    <div class="row" *ngIf="isDE">
                        <div class="col-sm-6">
                            <input-control name="taxNumber" [label]="'FORM_LABEL_TAX_NUMBER'|translate"
                                [form]="registrationForm" type="text"></input-control>
                        </div>
                        <div class="col-sm-6">
                            <input-control name="taxId" [label]="'FORM_LABEL_TAX_ID'|translate"
                                [form]="registrationForm" type="text"></input-control>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">
                            <input-control name="telefone" [label]="'FORM_LABEL_TELEFONE'|translate"
                                [form]="registrationForm" type="text"></input-control>
                        </div>
                        <div class="col-sm-4">
                            <input-control name="fax" [label]="'FORM_LABEL_FAX'|translate" [form]="registrationForm"
                                type="text"></input-control>
                        </div>
                        <div class="col-sm-4">
                            <input-control name="mobile" [label]="'FORM_LABEL_MOBILE'|translate"
                                [form]="registrationForm" type="text"></input-control>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <input-control name="street" [label]="'FORM_LABEL_STREET'|translate"
                                [form]="registrationForm" type="text"></input-control>
                        </div>
                        <div class="col-sm-4">
                            <input-control name="zip" [label]="'FORM_LABEL_ZIP'|translate" [form]="registrationForm"
                                type="text" [messages]="{'zip':'FORM_ERROR_ZIP'}"></input-control>
                        </div>
                        <div class="col-sm-8">
                            <input-control name="city" [label]="'FORM_LABEL_CITY'|translate" [form]="registrationForm"
                                type="text"></input-control>
                        </div>
                        <div class="col-sm-12">
                            <select-control *ngIf="isDE" name="country" [label]="'FORM_LABEL_COUNTRY'|translate"
                                [form]="registrationForm" [options]="countryOptionsDE"></select-control>
                            <select-control *ngIf="!isDE" name="country" [label]="'FORM_LABEL_COUNTRY'|translate"
                                [form]="registrationForm" [options]="countryOptionsCH"></select-control>
                        </div>
                    </div>
                    <!-- <div class="alert alert-danger" *ngIf="registrationForm.sent && !registrationForm.valid" translate>
                        FORM_ERROR_HINT</div> -->
                    <div class="info-buttons paragraph">
                        <p class="small" [innerHtml]="'REGISTRATION_DATA_SUB_TEXT'|translate"></p>
                        <button type="submit" *ngIf="!declineChecked" class="btn btn-primary btn-inverted"
                            translate>REGISTRATION_DATA_OK_BUTTON</button>
                        <a *ngIf="!changeRequired && !declineChecked" (click)="showChange = !showChange" class="btn btn-default" translate>CANCEL</a>
                    </div>
                </form>
                <div class="paragraph opt-in label-bg">
                    <input-control name="newsletter" [form]="registrationForm" type="checkbox">
                        <span [innerHtml]="'FORM_LABEL_NEWSLETTER'|translate"></span>
                    </input-control>
                    <input-control name="optIn" [form]="optInForm" type="checkbox">
                        <span [innerHtml]="'OPT_IN_TEXT'|translate"></span>
                    </input-control>
                    <input-control name="optInSupplier" [form]="optInForm" type="checkbox">
                        <span
                            [innerHtml]="'OPT_IN_SUPPLIER_TEXT'|translate:{name:supplierName, terms:showData.supplierTermsUrl, privacy:showData.supplierPrivacyUrl}"></span>
                    </input-control>
                </div>
                <p class="centered">
                    <button (click)="sendData()" *ngIf="!declineChecked && !showChange" type="button"
                        class="btn btn-primary" translate>REGISTER</button>
                    <button (click)="sendData()" *ngIf="!declineChecked &&showChange" type="button"
                        class="btn btn-primary" translate>REGISTER_CHANGE</button>
                    <button (click)="decline()" *ngIf="declineChecked" type="button" class="btn btn-primary"
                        translate>REGISTER_DECLINE</button>
                    <span class="req paragraph pull-right" translate>FORM_HELP_REQUIRED</span>
                </p>
                <div class="paragraph decline-box label-bg">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="checkbox">
                                <label>
                                    <input [ngModel]="declineChecked" (ngModelChange)="declineCheckedChange($event)"
                                        type="checkbox" class="form-control" value="true">
                                    <i class="far fa-square"></i>
                                    <i class="far fa-check"></i>
                                    <span [innerHtml]="'OPT_IN_DECLINE_TEXT'|translate:{name:supplierName}"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-banner form-foot" *ngIf="!isDomainCH()" [innerHtml]="'TEXT_BANNER_FORM' | translate"></div>
            </ng-template>
            <div *ngIf="registrationSuccess" class="register-success center">
                <img [src]="supplierLogo" [alt]="supplierName" class="supplier-logo center">
                <h1>{{'REGISTER_SUCCESS_TITLE'|translate}}</h1>
                <p *ngIf="!showChange" [innerHtml]="'REGISTER_SUCCESS_TEXT_NO_CHANGE'|translate"></p>
                <p *ngIf="showChange" [innerHtml]="'REGISTER_SUCCESS_TEXT_CHANGE'|translate"></p>
                <div class="text-banner result-foot" *ngIf="!isDomainCH()" [innerHtml]="'TEXT_BANNER_SUCCESS' | translate"></div>
            </div>
            <div *ngIf="registrationDeclined" class="register-success center">
                <img [src]="supplierLogo" [alt]="supplierName" class="supplier-logo">
                <h1 [innerHtml]="'REGISTER_DECLINED_TITLE'|translate"></h1>
                <p><span [innerHtml]="'REGISTER_DECLINED_TEXT'|translate"></span>&nbsp;
                <span [innerHtml]="'REGISTER_DECLINED_FOOT'|translate:{name:supplierName}"></span></p>
                <div class="text-banner result-foot"*ngIf="!isDomainCH()" [innerHTML]="'TEXT_BANNER_DECLINED' | translate"></div>
            </div>
            <div *ngIf="codeNotFound" [innerHtml]="'REGISTRATION_CODE_NOT_FOUND'|translate"></div>
            <div *ngIf="codeAlreadyUsed" [innerHtml]="'REGISTRATION_CODE_ALREADY_USED'|translate"></div>
        </div>
    </div>
    <div class="loading white" *ngIf="!ready"></div>
</section>